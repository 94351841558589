export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const GET_ALL_REQUEST = "GET_ALL_REQUEST"
export const GET_ALL_SUCCESS = "GET_ALL_SUCCESS"
export const GET_ALL_FAIL = "GET_ALL_FAIL"
export const LOGOUT_USER = "LOGOUT_USER"
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const UPDATE_U_IMAGE_REQUEST = "UPDATE_U_IMAGE_REQUEST"
export const UPDATE_U_IMAGE_SUCCESS = "UPDATE_U_IMAGE_SUCCESS"
export const UPDATE_U_IMAGE_FAIL = "UPDATE_U_IMAGE_FAIL"
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"
export const CREATE_CERT_REQUEST = "CREATE_CERT_REQUEST"
export const CREATE_CERT_SUCCESS = "CREATE_CERT_SUCCESS"
export const CREATE_CERT_FAIL = "CREATE_CERT_FAIL"
export const READ_CERT_REQUEST = "READ_CERT_REQUEST"
export const READ_CERT_SUCCESS = "READ_CERT_SUCCESS"
export const READ_CERT_FAIL = "READ_CERT_FAIL"