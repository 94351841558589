import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAIL,
    LOGOUT_USER,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_U_IMAGE_REQUEST,
    UPDATE_U_IMAGE_SUCCESS,
    UPDATE_U_IMAGE_FAIL,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    CREATE_CERT_REQUEST,
    CREATE_CERT_SUCCESS,
    CREATE_CERT_FAIL,
    READ_CERT_REQUEST,
    READ_CERT_SUCCESS,
    READ_CERT_FAIL,
} from "./constants"

const user = {
    jwt_key: "",
    currentUser: {},
    allUsers: [],
    normalizedUsers: [],

    certificates: {
        creating: false,
        created: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        userCertificates: []
    },

    loadingUsers: false,
    loadedUsers: false,

    loggingIn: false,
    loggedIn: false,

    registering: false,

    updatingUser: true,
    updatedUsers: false,

    creating: false,
    created: false,

    createError: "",
    updateError: "",
    loginError: "",
    registerError: "",
    gettingUsersError: ""
}

const UserReducer = (state = user, action) => {
    switch (action.type) {
        case CREATE_USER_REQUEST:
            return {
                ...state,
                creating: true,
                created: false,
                createError: ""
            }
        case CREATE_USER_SUCCESS:
            const _normalized = state?.normalizedUsers?.length === undefined ? [] : [...state.normalizedUsers]
            _normalized[action.payload.user._id] = action.payload.user
            return {
                ...state,
                creating: false,
                created: true,
                createError: "",
                allUsers: [...state.allUsers, action.payload.user],
                normalizedUsers: _normalized
            }
        case CREATE_USER_FAIL:
            return {
                ...state,
                creating: false,
                created: false,
                createError: action.payload.message
            }
        case REGISTER_REQUEST:
            return {
                ...state,
                registering: true
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                registering: false,
                loggedIn: true,
                currentUser: action.payload.user,
                jwt_key: action.payload.key
            }
        case REGISTER_FAIL:
            return {
                ...state,
                registering: false,
                registerError: action.payload.message
            }
        case LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loggedIn: false,
                loginError: "",
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                loginError: "",
                currentUser: action.payload.user,
                jwt_key: action.payload.Authentication
            }
        case LOGIN_FAIL:
            return {
                ...state,
                loggingIn: false,
                loggedIn: false,
                loginError: action.payload.message
            }
        case GET_ALL_REQUEST:
            return {
                ...state,
                loadingUsers: true,
                loadedUsers: false,
                gettingUsersError: ""
            }
        case GET_ALL_SUCCESS:
            const normalized = []
            for (const user of action.payload.allUsers) {
                normalized[user._id] = user
            }
            return {
                ...state,
                loadingUsers: false,
                loadedUsers: true,
                allUsers: action.payload.allUsers,
                normalizedUsers: normalized,
                gettingUsersError: ""
            }
        case GET_ALL_FAIL:
            return {
                ...state,
                allUsers: [],
                loadingUsers: false,
                loadedUsers: false,
                gettingUsersError: action.payload.message
            }
        case LOGOUT_USER:
            return {
                ...state,
                currentUser: {},
                loggedIn: false,
                loggingIn: false
            }
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                updatingUser: true,
                updatedUsers: false,
                updateError: ""
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updatingUser: false,
                updatedUsers: true,
                updateError: "",
                currentUser: parseInt(state.currentUser._id) === parseInt(action.payload.user._id) ? action.payload.user : state.currentUser,
                allUsers: [...state.allUsers.filter(user => user._id !== parseInt(action.payload.user._id)), action.payload.user]
            }
        case UPDATE_USER_FAIL:
            return {
                ...state,
                updatingUser: false,
                updatedUsers: false,
                updateError: action.payload.message
            }
        case UPDATE_U_IMAGE_REQUEST:
            return {
                ...state,
                updatingImage: true,
                updatedImage: false,
                updateImageError: ""
            }
        case UPDATE_U_IMAGE_SUCCESS:
            return {
                ...state,
                updatingImage: false,
                updatedImage: true,
                updateImageError: "",
                currentUser: parseInt(state.currentUser._id) === parseInt(action.payload.user._id) ? action.payload.user : state.currentUser,
                allUsers: [...state.allUsers.filter(user => user._id !== parseInt(action.payload.user._id)), action.payload.user]
            }
        case UPDATE_U_IMAGE_FAIL:
            return {
                ...state,
                updatingImage: false,
                updatedImage: false,
                updateImageError: action.payload.message
            }

        case CREATE_CERT_REQUEST:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
        case CREATE_CERT_SUCCESS:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    creating: false,
                    created: true,
                    createError: "",
                    certificates: [...state?.certificates?.certificates, action.payload.certificate]
                }
            }
        case CREATE_CERT_FAIL:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    creating: false,
                    created: false,
                    createError: action.payload.message,
                }
            }

        case READ_CERT_REQUEST:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
        case READ_CERT_SUCCESS:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    certificates: action.payload.certificates
                }
            }
        case READ_CERT_FAIL:
            return {
                ...state,
                certificates: {
                    ...state.certificates,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message,
                }
            }
        default:
            return { ...state };
    }
}

export default UserReducer