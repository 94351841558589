import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import store from './redux/store';
import { Provider } from 'react-redux';
import { register } from "./serviceWorkerRegistration"

const AppComponent = lazy(() => import('./App'))

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={() => (<h1>Loading... Please wait.</h1>)}>
      <Provider store={store} >
        <AppComponent />
      </Provider >
    </Suspense>
  </React.StrictMode>
  , document.getElementById('root')
)

register()