export const UPDATE_CANCEL_REQUEST = "UPDATE_CANCEL_REQUEST"
export const UPDATE_CANCEL_SUCCESS = "UPDATE_CANCEL_SUCCESS"
export const UPDATE_CANCEL_FAIL = "UPDATE_CANCEL_FAIL"
export const CREATE_JOB_REQUEST = "CREATE_JOB_REQUEST"
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS"
export const CREATE_JOB_FAIL = "CREATE_JOB_FAIL"
export const READ_JOB_REQUEST = "READ_JOB_REQUEST"
export const READ_JOB_SUCCESS = "READ_JOB_SUCCESS"
export const READ_JOB_FAIL = "READ_JOB_FAIL"
export const GET_JOB_REQUEST = "GET_JOB_REQUEST"
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS"
export const GET_JOB_FAIL = "GET_JOB_FAIL"
export const UPDATE_JOB_REQUEST = "UPDATE_JOB_REQUEST"
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS"
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL"
export const READ_JOB_HIST_REQUEST = "READ_JOB_HIST_REQUEST"
export const READ_JOB_HIST_SUCCESS = "READ_JOB_HIST_SUCCESS"
export const READ_JOB_HIST_FAIL = "READ_JOB_HIST_FAIL"
export const CREATE_OTHER_REQUEST = "CREATE_OTHER_REQUEST"
export const CREATE_OTHER_SUCCESS = "CREATE_OTHER_SUCCESS"
export const CREATE_OTHER_FAIL = "CREATE_OTHER_FAIL"
export const READ_OTHER_REQUEST = "READ_OTHER_REQUEST"
export const READ_OTHER_SUCCESS = "READ_OTHER_SUCCESS"
export const READ_OTHER_FAIL = "READ_OTHER_FAIL"
export const UPDATE_OTHER_REQUEST = "UPDATE_OTHER_REQUEST"
export const UPDATE_OTHER_SUCCESS = "UPDATE_OTHER_SUCCESS"
export const UPDATE_OTHER_FAIL = "UPDATE_OTHER_FAIL"
export const CREATE_PAUSE_REQUEST = "CREATE_PAUSE_REQUEST"
export const CREATE_PAUSE_SUCCESS = "CREATE_PAUSE_SUCCESS"
export const CREATE_PAUSE_FAIL = "CREATE_PAUSE_FAIL"
export const READ_PAUSE_REQUEST = "READ_PAUSE_REQUEST"
export const READ_PAUSE_SUCCESS = "READ_PAUSE_SUCCESS"
export const READ_PAUSE_FAIL = "READ_PAUSE_FAIL"
export const UPDATE_PAUSE_REQUEST = "UPDATE_PAUSE_REQUEST"
export const UPDATE_PAUSE_SUCCESS = "UPDATE_PAUSE_SUCCESS"
export const UPDATE_PAUSE_FAIL = "UPDATE_PAUSE_FAIL"
export const CREATE_IMAGE_REQUEST = "CREATE_IMAGE_REQUEST"
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS"
export const CREATE_IMAGE_FAIL = "CREATE_IMAGE_FAIL"
export const READ_IMAGE_REQUEST = "READ_IMAGE_REQUEST"
export const READ_IMAGE_SUCCESS = "READ_IMAGE_SUCCESS"
export const READ_IMAGE_FAIL = "READ_IMAGE_FAIL"
export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST"
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS"
export const CREATE_COMMENT_FAIL = "CREATE_COMMENT_FAIL"
export const READ_COMMENT_REQUEST = "READ_COMMENT_REQUEST"
export const READ_COMMENT_SUCCESS = "READ_COMMENT_SUCCESS"
export const READ_COMMENT_FAIL = "READ_COMMENT_FAIL"
export const CREATE_CANCEL_REQUEST = "CREATE_CANCEL_REQUEST"
export const CREATE_CANCEL_SUCCESS = "CREATE_CANCEL_SUCCESS"
export const CREATE_CANCEL_FAIL = "CREATE_CANCEL_FAIL"
export const READ_CANCEL_REQUEST = "READ_CANCEL_REQUEST"
export const READ_CANCEL_SUCCESS = "READ_CANCEL_SUCCESS"
export const READ_CANCEL_FAIL = "READ_CANCEL_FAIL"
export const CREATE_DECLINE_REQUEST = "CREATE_DECLINE_REQUEST"
export const CREATE_DECLINE_SUCCESS = "CREATE_DECLINE_SUCCESS"
export const CREATE_DECLINE_FAIL = "CREATE_DECLINE_FAIL"
export const UPDATE_DECLINE_REQUEST = "UPDATE_DECLINE_REQUEST"
export const UPDATE_DECLINE_SUCCESS = "UPDATE_DECLINE_SUCCESS"
export const UPDATE_DECLINE_FAIL = "UPDATE_DECLINE_FAIL"
export const READ_DECLINE_REQUEST = "READ_DECLINE_REQUEST"
export const READ_DECLINE_SUCCESS = "READ_DECLINE_SUCCESS"
export const READ_DECLINE_FAIL = "READ_DECLINE_FAIL"
export const CREATE_CREDIT_REQUEST = "CREATE_CREDIT_REQUEST"
export const CREATE_CREDIT_SUCCESS = "CREATE_CREDIT_SUCCESS"
export const CREATE_CREDIT_FAIL = "CREATE_CREDIT_FAIL"
export const UPDATE_CREDIT_REQUEST = "UPDATE_CREDIT_REQUEST"
export const UPDATE_CREDIT_SUCCESS = "UPDATE_CREDIT_SUCCESS"
export const UPDATE_CREDIT_FAIL = "UPDATE_CREDIT_FAIL"
export const READ_CREDIT_REQUEST = "READ_CREDIT_REQUEST"
export const READ_CREDIT_SUCCESS = "READ_CREDIT_SUCCESS"
export const READ_CREDIT_FAIL = "READ_CREDIT_FAIL"
export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST"
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS"
export const CREATE_REVIEW_FAIL = "CREATE_REVIEW_FAIL"
export const READ_REVIEW_REQUEST = "READ_REVIEW_REQUEST"
export const READ_REVIEW_SUCCESS = "READ_REVIEW_SUCCESS"
export const READ_REVIEW_FAIL = "READ_REVIEW_FAIL"
export const CREATE_DELETE_REQUEST = "CREATE_DELETE_REQUEST"
export const CREATE_DELETE_SUCCESS = "CREATE_DELETE_SUCCESS"
export const CREATE_DELETE_FAIL = "CREATE_DELETE_FAIL"
export const READ_DELETE_REQUEST = "READ_DELETE_REQUEST"
export const READ_DELETE_SUCCESS = "READ_DELETE_SUCCESS"
export const READ_DELETE_FAIL = "READ_DELETE_FAIL"
export const SEE_REVIEW_REQUEST = "SEE_REVIEW_REQUEST"
export const SEE_REVIEW_SUCCESS = "SEE_REVIEW_SUCCESS"
export const SEE_REVIEW_FAIL = "SEE_REVIEW_FAIL"